import { createContext, useReducer, FC, ReactNode } from "react";
// Types
type Props = { children: ReactNode };
type ErrorStatusContextType = boolean;
type ActionType = boolean;
type ErrorStatusContextValue = {
  errorStatusProvided: ErrorStatusContextType;
  setErrorStatusProvided: React.Dispatch<ActionType>;
};

const ErrorStatusContext = createContext<ErrorStatusContextValue>({
  errorStatusProvided: false as ErrorStatusContextType,
  setErrorStatusProvided: () => {},
});

const initialErrorStatus = false;
const ErrorStatusReducer = (
  initialErrorStatus: boolean,
  action: ActionType
): ErrorStatusContextType => {
  return action;
};

const ErrorStatusProvider: FC<Props> = ({ children }) => {
  const [errorStatus, setErrorStatus] = useReducer(
    ErrorStatusReducer,
    initialErrorStatus
  );
  return (
    <ErrorStatusContext.Provider
      value={{
        errorStatusProvided: errorStatus,
        setErrorStatusProvided: setErrorStatus,
      }}
    >
      {children}
    </ErrorStatusContext.Provider>
  );
};

export default ErrorStatusContext;
export { ErrorStatusProvider, initialErrorStatus };
