import React from "react";
import { Button } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const AddUserButton = ({onAddUserClick}:{onAddUserClick: () => Promise<void>}) => {
  return (
    <Button
      component="label"
      variant="outlined"
      color="success"
      startIcon={<PersonAddIcon />}
      fullWidth
      onClick={onAddUserClick}
    >
      ユーザーを追加
    </Button>
  );
};

export default AddUserButton;
