import React, { useContext } from "react";
// mui
import { Snackbar, Alert, ThemeProvider, SnackbarCloseReason } from "@mui/material";
// Contexts
import ErrorStatusContext from "../contexts/ErrorStatusContext";
// Style
import { darkTheme } from "../darkTheme";

const ErrorSnackBar: React.FC = () => {
  const { errorStatusProvided, setErrorStatusProvided } =
    useContext(ErrorStatusContext);

  const snackBarHandleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorStatusProvided(false);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Snackbar
        open={errorStatusProvided}
        autoHideDuration={6000}
        onClose={snackBarHandleClose}
      >
        <Alert
          onClose={snackBarHandleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          入力が正しくありません。もう一度ご確認ください。
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default ErrorSnackBar;