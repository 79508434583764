// Ampify
import { API } from "aws-amplify";
import {
  listProjects,
  getProjectById,
  listPileSetsByProjectId,
  getMeshByProjectId,
  getPilesByPileSetId,
} from "../../graphql/queries";
import {
  createProject,
  createMeshAndPileSet,
  deletePileSet,
  deleteProject,
  createUser,
  deleteUser,
} from "../../graphql/mutations";

// Queries
export const amplifyGetProjectById = async (projectId: string) => {
  const projectRes = await API.graphql({
    query: getProjectById,
    variables: { projectId },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  return projectRes;
};

export const amplifyCreateProject = async (
  name: string,
  users: string[],
  viewers: string[]
) => {
  // create project
  const projectRes: any = await API.graphql({
    query: createProject,
    variables: { projectInput: { name, users, viewers } },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  const project = projectRes.data.createProject;
  return project;
};

export const amplifyListPileSetsByProjectId = async (projectId: string) => {
  const pileSets = await API.graphql({
    query: listPileSetsByProjectId,
    variables: { projectId },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  return pileSets;
};

export const amplifyGetPilesByPileSetId = async (
  projectId: string,
  pileSetId: string
) => {
  const pilesRes = (await API.graphql({
    query: getPilesByPileSetId,
    variables: {
      projectId,
      pileSetId,
    },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })) as any;
  return pilesRes;
};
// Mutations

export const amplifyDeletePileSet = async (
  projectId: string,
  pileSetId: string
) => {
  try {
    await API.graphql({
      query: deletePileSet,
      variables: { projectId, pileSetId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const amplifyDeleteProject = async (projectId: string) => {
  try {
    await API.graphql({
      query: deleteProject,
      variables: { projectId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const amplifyCreateUser = async (
  username: string,
  email: string,
  password: string
) => {
  try {
    await API.graphql({
      query: createUser,
      variables: { username, email, password },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const amplifyDeleteUser = async (
  username: string
) => {
  try {
    await API.graphql({
      query: deleteUser,
      variables: { username },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return true;
  } catch(error){
    console.error(error);
    return false
  }
}
