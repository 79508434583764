import * as THREE from "three";
// Config
import { scale } from "../config/ViewerConfig";
import { initialWorkArea } from "../config/PileConfig";
// Utils
import { strToDate, dateToStr } from "./csvUtils";
import getIntersection from "./Intersections";
// Type
import {
  PileInputType,
  PileType,
  PileCSVType,
  PileDataType,
  MarginOptionType,
  PileFilterType,
  WorkAreaType,
} from "../../types/pile.types";
import { MeshGeometryType } from "../../types/mesh.types";

// CSV to DB
const PileInput = (props: PileCSVType): PileInputType => {
  const data = {
    index: props.index,
    symbol: props.symbol,
    diameter: parseFloat(props.diameter),
    work_area: props.work_area,
    order: props.order,
    x: parseFloat(props.x),
    y: parseFloat(props.y),
    top_level_design: parseFloat(props.top_level_design),
    pile_length_design: parseFloat(props.pile_length_design),
    min_embedment_design: parseFloat(props.min_embedment_design),
    soil_depth_design: parseFloat(props.soil_depth_design),
    extra_embedment_design: parseFloat(props.extra_embedment_design),
    has_middle_plan: props.has_middle_plan === "有" ? true : false,
    top_level_plan: parseFloat(props.top_level_plan),
    pile_length_plan: parseFloat(props.pile_length_plan),
    min_embedment_plan: parseFloat(props.min_embedment_plan),
    soil_depth_plan: parseFloat(props.soil_depth_plan),
    extra_embedment_plan: parseFloat(props.extra_embedment_plan),
    top_level_actual: parseFloat(props.top_level_actual),
    pile_length_actual: parseFloat(props.pile_length_actual),
    soil_depth_actual: parseFloat(props.soil_depth_actual),
    extra_embedment_actual: parseFloat(props.extra_embedment_actual),
    date_of_work: props.date_of_work,
    description: props.description,
  };
  return data;
};

// DB to Pile
const Pile = (pileInput: PileInputType): PileType => {
  const newPile = {
    ...pileInput,
    date_of_work: strToDate(pileInput.date_of_work),
    // 余長ごとの区分け
    extraLength: 0,
    extraLengthIndex: 0,
  } as PileType;
  return newPile;
};

// export時に無視するプロパティ
const ignorePileExport = [
  "id",
  "extraLength",
  "extraLengthIndex",
  "__typename",
  "createdAt",
  "updatedAt",
];

const typeCSVforPile = (array: PileInputType[]): PileType[] => {
  const typedCSVArray = array.map((item: PileInputType, index: number) => {
    item.id = index;
    const obj = Pile(item);
    return obj;
  });
  return typedCSVArray;
};

const typeCSVforPileInput = (array: PileCSVType[]): PileInputType[] => {
  const typedCSVArray = array.map((item: PileCSVType) => {
    const obj = PileInput(item);
    return obj;
  });
  return typedCSVArray;
};

const PileInputToPile = (pileInputs: PileInputType[]): PileType[] => {
  return pileInputs.map((pileInput: PileInputType) => {
    return Pile(pileInput);
  });
};

const PileToPileInput = (piles: PileDataType[]): PileInputType[] => {
  return piles.map(({ props }) => {
    const {
      date_of_work,
      extraLength,
      extraLengthIndex,
      id,
      __typename,
      createdAt,
      updatedAt,
      ...rest
    } = props;

    return {
      ...rest,
      date_of_work: dateToStr(date_of_work),
    };
  });
};

type ValueKey = keyof Pick<
  PileType,
  | "top_level_design"
  | "top_level_plan"
  | "top_level_actual"
  | "pile_length_design"
  | "pile_length_plan"
  | "pile_length_actual"
  | "soil_depth_design"
  | "soil_depth_plan"
  | "soil_depth_actual"
  | "extra_embedment_design"
  | "extra_embedment_plan"
  | "extra_embedment_actual"
>;

// actual -> plan -> design
const getNewestValue = (
  pile: PileType,
  key: Omit<ValueKey, "_actual" | "_plan" | "_design">
): number => {
  const actualKey = `${key}_actual` as ValueKey;
  const planKey = `${key}_plan` as ValueKey;
  const designKey = `${key}_design` as ValueKey;
  if (Number.isNaN(pile[actualKey]) || !(actualKey in pile)) {
    if (Number.isNaN(pile[planKey]) || !(planKey in pile)) {
      return pile[designKey];
    } else {
      return pile[planKey];
    }
  } else {
    return pile[actualKey];
  }
};
// const getNewestValue = (pile, key) => {
//   if (Number.isNaN(pile[key + "_actual"]) || !(key + "_actual" in pile)) {
//     if (Number.isNaN(pile[key + "_plan"]) || !(key + "_plan" in pile)) {
//       return pile[key + "_design"];
//     } else {
//       return pile[key + "_plan"];
//     }
//   } else {
//     return pile[key + "_actual"];
//   }
// };

// 点情報をpileにセット
const setPileData = (piles: PileDataType[]) => {
  const newPiles = piles.map((pile) => {
    const newPile = structuredClone(pile);
    const { props } = newPile;
    const top_level = getNewestValue(props, "top_level");
    // cylinder生成点
    const pile_length = getNewestValue(props, "pile_length");
    const point = new THREE.Vector3(
      props.x / scale,
      props.y / scale,
      (top_level - pile_length / 2) / scale
    );
    // 杭天座標
    const origin = new THREE.Vector3(
      props.x / scale,
      props.y / scale,
      top_level / scale
    );
    // 杭底座標
    const bottom = new THREE.Vector3(
      props.x / scale,
      props.y / scale,
      (top_level - pile_length) / scale
    );
    // 杭長
    const pileLength = pile_length / scale;
    // 杭径(直径)
    const pileDiameter = props.diameter / scale;
    newPile.point = point;
    newPile.origin = origin;
    newPile.bottom = bottom;
    newPile.length = pileLength;
    newPile.extraLength = 0;
    newPile.diameter = pileDiameter;
    return newPile;
  });
  return newPiles;
};

// 杭の表示ステータスを更新
const updatePiles = (
  piles: PileDataType[],
  mesh: MeshGeometryType,
  pileMarginOption: MarginOptionType,
  pileFilter: PileFilterType,
  fillEmpty: boolean = false
) => {
  const newPiles = [...piles];
  for (let i = 0, len = newPiles.length; i < len; i = (i + 1) | 0) {
    const pile = newPiles[i];
    const { props } = pile;
    const top_level = getNewestValue(props, "top_level");
    // cylinder生成点
    const pile_length = getNewestValue(props, "pile_length");
    pile.point = new THREE.Vector3(
      props.x / scale,
      props.y / scale,
      (top_level - pile_length / 2) / scale
    );
    // 杭天座標
    pile.origin = new THREE.Vector3(
      props.x / scale,
      props.y / scale,
      top_level / scale
    );
    // 杭底座標
    pile.bottom = new THREE.Vector3(
      props.x / scale,
      props.y / scale,
      (top_level - pile_length) / scale
    );
    // 杭長
    pile.length = pile_length / scale;
    // 杭径(直径)
    pile.diameter = props.diameter / scale;
    // 実施余長計算
    let intersection = pile.origin;
    if (!Number.isNaN(pile.props.soil_depth_actual)) {
      const dammyIntersection = new THREE.Vector3(
        pile.origin.x,
        pile.origin.y,
        pile.props.soil_depth_actual / scale
      );

      const { extraLength } = getExtraLength(
        pile,
        dammyIntersection,
        pileMarginOption
      );
      // pile.props.extra_embedment_actual = adjustNumberForRightPanel(
      //   extraLength * scale
      // );
      pile.props.extra_embedment_actual = Math.round(extraLength * scale);
    }
    // 余長計算
    intersection = getIntersection(pile.bottom, mesh.geometry) as THREE.Vector3;
    if (intersection === null) {
      intersection = new THREE.Vector3(
        pile.origin.x,
        pile.origin.y,
        pile.props.soil_depth_actual / scale
      );
    }
    const { extraLength, requiredExtraLength } = getExtraLength(
      pile,
      intersection,
      pileMarginOption
    );

    pile.extraLength = extraLength;
    pile.requiredExtraLength = requiredExtraLength;
    pile.props.extra_embedment_plan = Math.round(extraLength * scale);
    pile.props.soil_depth_plan = intersection.z * scale;
    // 支持層深度（設計）」「根入れ余長（設計）」の列が空白の場合に計画の値を代入する
    if (fillEmpty) {
      Number.isNaN(pile.props.extra_embedment_design) && (pile.props.extra_embedment_design = pile.props.extra_embedment_plan);
      Number.isNaN(pile.props.soil_depth_design) && (pile.props.soil_depth_design = pile.props.soil_depth_plan);
    }
    // 杭の色分けインデックスを更新
    // console.log(parseInt(pile.props.index), extraLength);
    pile.extraLengthIndex = getExtraLengthIndex(extraLength, pileFilter.range);
  }
  return newPiles;
};

// 杭の色分けインデックスを更新
const updatePileExtraLengthIndex = (
  piles: PileDataType[],
  pileFilter: PileFilterType
) => {
  const newPiles = [...piles];
  newPiles.forEach((pile) => {
    pile.extraLengthIndex = getExtraLengthIndex(
      pile.extraLength,
      pileFilter.range
    );
  });
  return newPiles;
};

// 余長計算
const getExtraLength = (
  pile: PileDataType,
  intersection: THREE.Vector3,
  marginOption: MarginOptionType
) => {
  let requiredExtraLength = 0;
  switch (marginOption.method) {
    case 0:
      // if (!isNaN(parseFloat(marginOption.param2))) {
      if (!isNaN(marginOption.param2)) {
        const min_embedment = getNewestValue(pile.props, "min_embedment");
        requiredExtraLength =
          // parseFloat(min_embedment) + parseFloat(marginOption.param2);
          min_embedment + marginOption.param2;
      } else {
        break;
      }
      break;
    case 1:
      if (
        // !isNaN(parseFloat(marginOption.param3)) &&
        // !isNaN(parseFloat(marginOption.param4))
        !isNaN(marginOption.param3) &&
        !isNaN(marginOption.param4)
      ) {
        requiredExtraLength =
          // pile.diameter * scale * parseFloat(marginOption.param3) +
          // parseFloat(marginOption.param4);
          Math.round(
            pile.diameter * scale * marginOption.param3 + marginOption.param4
          );
      } else {
        break;
      }
      break;
    default:
      requiredExtraLength = 1000;
  }
  // 余長 = 根入れ長さ – 必要根入れ長さ
  const extraLength = parseFloat(
    (intersection.z - pile.bottom.z - requiredExtraLength / scale).toFixed(3)
  );
  return { extraLength, requiredExtraLength };
};

// 仮の判別式
const getExtraLengthIndex = (extraLength: number, range: number[]) => {
  if (extraLength < range[0] / scale) {
    return 0;
  } else if (extraLength < range[1] / scale) {
    return 1;
  } else if (extraLength < range[2] / scale) {
    return 2;
  } else {
    return 3;
  }
};

// meshに追加するポイントを抽出
const getPilePointsAddToMesh = (piles: PileDataType[]): THREE.Vector3[] => {
  const points = piles
    .map((pile) => {
      if (!Number.isNaN(pile.props.soil_depth_actual)) {
        const point = new THREE.Vector3(
          pile.origin.x,
          pile.origin.y,
          pile.props.soil_depth_actual / scale
        );
        return point;
      }
    })
    .filter((e) => e !== undefined) as THREE.Vector3[];
  return points;
};

// 文字数を調整
const adjustNumber = (number: number, length: number): string => {
  const str = !Number.isNaN(number) ? (number / scale).toFixed(1) : "";
  return str.padStart(length, " ");
};

// 右パネル用の数値調整
const adjustNumberForRightPanel = (number: number): string => {
  const str = !Number.isNaN(number) ? (number / 1.0).toFixed(0) : "";
  return str;
};

// Annotationに追加する文字を生成
const textLength = 7;
const getPileAnnotation = (pile: PileDataType) => {
  const text =
    `     設      計      実\n` +
    `天${adjustNumber(pile.props.top_level_design, textLength)}${adjustNumber(
      pile.props.top_level_plan,
      textLength
    )}${adjustNumber(pile.props.top_level_actual, textLength)}\n` +
    `長${adjustNumber(pile.props.pile_length_design, textLength)}${adjustNumber(
      pile.props.pile_length_plan,
      textLength
    )}${adjustNumber(pile.props.pile_length_actual, textLength)}\n` +
    `支${adjustNumber(pile.props.soil_depth_design, textLength)}${adjustNumber(
      pile.props.soil_depth_plan,
      textLength
    )}${adjustNumber(pile.props.soil_depth_actual, textLength)}\n` +
    `余${adjustNumber(
      pile.props.extra_embedment_design,
      textLength
    )}${adjustNumber(
      pile.props.extra_embedment_plan,
      textLength
    )}${adjustNumber(pile.props.extra_embedment_actual, textLength)}`;
  return text;
};

// 打設日で杭の色を変更
const changePileColorByDate = (color: string, isPileDateValid: boolean) => {
  // var hsl = new THREE.Color();
  var hsl = { h: 0, s: 0, l: 0 };
  const colorRGB = new THREE.Color(color);
  colorRGB.getHSL(hsl);
  const h = hsl.h;
  const s = isPileDateValid ? hsl.s * 0.7 : hsl.s;
  // const l = hsl.s < 0.01 ? (isPileDateValid < 0.01 ? hsl.l : 0.4) : hsl.l;
  const l = hsl.s < 0.01 ? (!isPileDateValid ? hsl.l : 0.4) : hsl.l;
  var newColor = new THREE.Color();
  newColor.setHSL(h, s, l);
  return newColor;
};

const darkenColor = (color: THREE.ColorRepresentation | undefined) => {
  // var hsl = new THREE.Color();
  var hsl = { h: 0, s: 0, l: 0 };
  const colorRGB = new THREE.Color(color);
  colorRGB.getHSL(hsl);
  const h = hsl.h;
  const s = hsl.s;
  const l = hsl.s === 0 ? hsl.l * 0.2 : hsl.l * 0.3;
  var newColor = new THREE.Color();
  newColor.setHSL(h, s, l);
  return newColor;
};

const getWorkAreaList = (piles: PileDataType[]): WorkAreaType[] => {
  const workAreaList: WorkAreaType[] = [];
  let index = 1;
  piles.forEach((pile) => {
    if (
      !workAreaList.some((e) => e.name === pile.props.work_area) &&
      pile.props.work_area !== ""
    ) {
      workAreaList.push({
        index: index++,
        key: `workArea${index}`,
        name: pile.props.work_area,
      });
    }
  });
  workAreaList.sort((a, b) => a.name.localeCompare(b.name)); // Sort the array in ascending order based on the name property
  workAreaList.unshift(initialWorkArea[0]);
  return workAreaList;
};

const calculateBrightness = (hexColor: string): number => {
  const rgb = parseInt(hexColor.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  return (r * 299 + g * 587 + b * 114) / 1000;
};

// 背景色の明るさに基づいて文字色を決定する関数
const getTextColor = (hexColor: string): "#000000" | "#FFFFFF" => {
  const brightness = calculateBrightness(hexColor);
  return brightness > 70 ? "#000000" : "#FFFFFF";
};

// 「支持層深度（設計）」「根入れ余長（設計）」の列が空欄かどうかを判定
const isSoilDepthDesignEmpty = (pileObjectArray: PileCSVType[]): boolean => {
  return pileObjectArray.some(
    (pile: PileCSVType) =>
      pile.soil_depth_design === "" || pile.extra_embedment_design === ""
  );
};

export {
  typeCSVforPileInput,
  typeCSVforPile,
  PileInputToPile,
  PileToPileInput,
  getNewestValue,
  updatePiles,
  updatePileExtraLengthIndex,
  getExtraLength,
  getExtraLengthIndex,
  ignorePileExport,
  getPilePointsAddToMesh,
  setPileData,
  getPileAnnotation,
  adjustNumberForRightPanel,
  changePileColorByDate,
  darkenColor,
  getWorkAreaList,
  getTextColor,
  isSoilDepthDesignEmpty,
};
