import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ThemeProvider,
  TextField,
  Typography,
} from "@mui/material";
// Context
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";
// Theme
import { darkTheme } from "../darkTheme";
import PilePanelContext from "../contexts/PilePanelContext";
import ProgressContext from "../contexts/ProgressContext";
import UploadStatusContext from "../contexts/UploadStatusContext";
// Type
// API
import { amplifyCreateUser } from "../utils/amplifyUtils";

type Result = string;

type AddUserDialogProps = {
  open: boolean;
  onClose: (result: Result) => void;
};

const handleClose = (
  event: React.MouseEvent<HTMLInputElement>,
  reason: "backdropClick"
) => {
  if (reason === "backdropClick") return;
};

const FILLEMPTYDESIGN_DIALOG = ({ open, onClose }: AddUserDialogProps) => {
  const title = "新規ユーザーを追加";
  const message = "ユーザーの情報を入力してください。";
  // Context
  const { setProgressProvided } = useContext(ProgressContext);
  const { setUploadStatusProvided } = useContext(UploadStatusContext);
  // Ref
  const userName = useRef<HTMLInputElement>(null);
  const mailAddress = useRef<HTMLInputElement>(null);
  const initialPassword = useRef<HTMLInputElement>(null);
  // Validation
  const [inputError, setInputError] = useState({
    userName: true,
    mailAddress: true,
    initialPassword: true,
  });
  const handleTextFieldChange = () => {
    if (userName.current && userName.current.value === "") {
      setInputError({ ...inputError, userName: true });
    } else {
      setInputError({ ...inputError, userName: false });
    }
  };
  const handleMailTextFieldChange = () => {
    if (mailAddress.current && (mailAddress.current.value === "" || !mailAddress.current.value.includes("@"))) {
      setInputError({ ...inputError, mailAddress: true });
    } else {
      setInputError({ ...inputError, mailAddress: false });
    }
  };
  const handleInitialPasswordTextFieldChange = () => {
    if (initialPassword.current && (initialPassword.current.value === "" || initialPassword.current.value.length < 8)) {
      setInputError({ ...inputError, initialPassword: true });
    } else {
      setInputError({ ...inputError, initialPassword: false });
    }
  };
  const onClickAddButton = async () => {
    if (
      userName.current &&
      mailAddress.current &&
      initialPassword.current &&
      !inputError.userName &&
      !inputError.mailAddress &&
      !inputError.initialPassword
    ) {
      setProgressProvided(true);
      try {
        const res = await amplifyCreateUser(
          userName.current.value,
          mailAddress.current.value,
          initialPassword.current.value
        );
        if (!res) {
          setProgressProvided(false);
          alert("エラーが発生しました。入力内容をもう一度ご確認ください。");
          return;
        }
        setUploadStatusProvided(true);
        setProgressProvided(false);
        onClose("User Added.");
      } catch (e) {
        console.log(e);
        setProgressProvided(false);
      }
    } else {
      alert("エラーが発生しました。入力内容をもう一度ご確認ください。");
    }
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <Typography variant="subtitle1">ユーザー名</Typography>
        <TextField
          id="userName"
          variant="outlined"
          size="small"
          sx={{ width: "100%" }}
          defaultValue=""
          inputRef={userName}
          helperText={inputError.userName ? "ユーザー名を入力してください" : ""}
          required
          error={inputError.userName}
          onChange={handleTextFieldChange}
        />
        <Typography variant="subtitle1">メールアドレス</Typography>
        <TextField
          id="mailAddress"
          variant="outlined"
          size="small"
          sx={{ width: "100%" }}
          defaultValue=""
          inputRef={mailAddress}
          helperText={
            inputError.mailAddress ? "有効なメールアドレスを入力してください" : ""
          }
          required
          error={inputError.mailAddress}
          onChange={handleMailTextFieldChange}
        />
        <Typography variant="subtitle1">初期パスワード</Typography>
        <TextField
          id="initialPassword"
          variant="outlined"
          size="small"
          sx={{ width: "100%" }}
          defaultValue=""
          inputRef={initialPassword}
          helperText={
            inputError.initialPassword  ? "8文字以上の英数字でパスワードを入力してください" : ""
          }
          required
          error={inputError.initialPassword}
          onChange={handleInitialPasswordTextFieldChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose("no")} variant="outlined">
          キャンセル
        </Button>
        <Button onClick={onClickAddButton} variant="contained" autoFocus>
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useAddUserDialog = () => {
  const [open, setOpen] = useState(false);
  const [resolve, setResolve] = useState<
    ((value: Result | PromiseLike<Result>) => void) | null
  >(null);

  const openAddUserDialog = () => {
    setOpen(true);
    return new Promise<Result>((res) => {
      setResolve(() => res);
    });
  };

  const AddUserDialog = () => {
    // Context
    const { projectProvided } = useContext(ProjectContext);
    const { objectProvided, setObjectProvided } = useContext(ObjectContext);
    const { setPilePanelProvided } = useContext(PilePanelContext);

    const onClose = (result: Result) => {
      switch (result) {
        case "yes":
          setPilePanelProvided(true);
          break;
        case "no":
          setPilePanelProvided(true);
          break;
      }
      // setObjectProvided({
      //   type: "setStashedPile",
      //   payload: undefined,
      // });
      setOpen(false);
      if (resolve) {
        resolve(result);
      }
    };

    useEffect(() => {
      if (projectProvided.selectedPile && objectProvided.stashedPile) {
        setOpen(true);
      }
    }, [projectProvided.selectedPile, objectProvided.stashedPile]);
    return (
      <ThemeProvider theme={darkTheme}>
        <FILLEMPTYDESIGN_DIALOG open={open} onClose={onClose} />
      </ThemeProvider>
    );
  };

  return {
    AddUserDialog,
    openAddUserDialog,
  };
};

export default useAddUserDialog;
