import { Amplify, Auth } from "aws-amplify";
import { Authenticator, useTheme, ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsconfig from "./aws-exports";

import TopPage from "./components/TopPage";
import Viewer from "./components/Viewer";
import Header from "./components/Header";
import LeftPanel from "./components/LeftPanel";
import RightPanel from "./components/RightPanel";
import UploadSnackBar from "./components/items/UploadSnackBar";
import ErrorSnackBar from "./components/items/ErrorSnackBar";
import useStashDialog from "./components/items/useStashDialog";
import "./App.css";
import React from "react";
// Contexts
import { PilePanelProvider } from "./components/contexts/PilePanelContext";
import { CameraProvider } from "./components/contexts/CameraContext";
import { UploadStatusProvider } from "./components/contexts/UploadStatusContext";
import { ErrorStatusProvider } from "./components/contexts/ErrorStatusContext";
import { ProgressProvider } from "./components/contexts/ProgressContext";
import { ProjectProvider } from "./components/contexts/ProjectContext";
import { ObjectProvider } from "./components/contexts/ObjectContext";
// Utils
import { formFields, components } from "./components/utils/authUtils";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const App = () => {
  const { tokens } = useTheme();
  const theme = {
    name: "Auth Example Theme",
    tokens: {
      colors: {
        background: {
          primary: {
            value: "#121212",
          },
          secondary: {
            value: "#212121",
          },
        },
        font: {
          interactive: {
            value: tokens.colors.white.value,
          },
          primary: {
            value: "#fff",
          },
          secondary: {
            value: "rgba(255, 255, 255, 0.7)",
          },
        },
        brand: {
          primary: {
            10: "#212121",
            80: "#1976d2",
            90: "#42a5f5",
            100: "#1565c0",
          },
        },
      },
    },
  };
  // useStashDialog();
  const { StashDialog } = useStashDialog();

  return (
    <ThemeProvider theme={theme}>
      <Authenticator
        variation="modal"
        formFields={formFields}
        components={components}
        hideSignUp={true}
      >
        {({ signOut, user }) => (
          <CameraProvider>
            <UploadStatusProvider>
              <ErrorStatusProvider>
                <ProgressProvider>
                  <ProjectProvider>
                    <ObjectProvider>
                      <div className="App" id="wrapper">
                        <header>
                          <Header />
                        </header>
                        <main>
                          <PilePanelProvider>
                            <div>
                              <TopPage />
                            </div>
                            <div>
                              <LeftPanel />
                            </div>
                            <div>
                              <RightPanel />
                            </div>
                            <div>
                              <Viewer />
                            </div>
                            <div>
                              <UploadSnackBar />
                            </div>
                            <div>
                              <ErrorSnackBar />
                            </div>
                            <StashDialog
                              title="確認"
                              message="一時保存されている杭データがあります。復元しますか？"
                            />
                          </PilePanelProvider>
                        </main>
                      </div>
                    </ObjectProvider>
                  </ProjectProvider>
                </ProgressProvider>
              </ErrorStatusProvider>
            </UploadStatusProvider>
          </CameraProvider>
        )}
      </Authenticator>
    </ThemeProvider>
  );
};

export default App;
