import React, { useContext, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ThemeProvider,
  Stack,
} from "@mui/material";
// Context
import ProjectContext, {initialProject} from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";
import ErrorStatusContext from "../contexts/ErrorStatusContext";
// Items
import RestoreSelect from "./RestoreSelect";
// Utils
import {
  amplifyListPileSetsByProjectId,
  amplifyGetPilesByPileSetId,
} from "../utils/amplifyUtils";
import { replaceNullWithNaN } from "../utils/csvUtils";
import { PileInputToPile } from "../utils/pileUtils";
import { createPileGeometry } from "../utils/objectUtils";
// Types
import {
  PileCSVType,
  PileDataType,
  PileInputType,
} from "../../types/pile.types";
// Theme
import { darkTheme } from "../darkTheme";
import ProgressContext from "../contexts/ProgressContext";

type Result = "confirm" | "cancel";
type RestoreItemType = "project" | "pileSet";

type RestoreItemDialogProps = {
  open: boolean;
  onClose: (result: Result) => void;
  itemType: RestoreItemType;
  title: string;
  message: string;
  textFieldRef: React.RefObject<HTMLInputElement>;
};

const handleClose = (
  event: React.MouseEvent<HTMLInputElement>,
  reason: "backdropClick"
) => {
  if (reason === "backdropClick") return;
};

const RESTORE_ITEM_DIALOG = ({
  open,
  onClose,
  title,
  message,
  itemType,
  textFieldRef,
}: RestoreItemDialogProps) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
    <Stack spacing={1.0}>
      <DialogContentText>{message}</DialogContentText>
      <DialogContentText>
        復元する{itemType === "project" ? "プロジェクト名" : "杭リスト名"}
        を選択してください。
      </DialogContentText>
      <RestoreSelect title={itemType === "project" ? "プロジェクト名" : "杭リスト名"} />
    </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose("cancel")} variant="outlined">
        キャンセル
      </Button>
      <Button
        onClick={() => onClose("confirm")}
        variant="contained"
        color="error"
        autoFocus
      >
        復元
      </Button>
    </DialogActions>
  </Dialog>
);

const useRestoreItemDialog = () => {
  const [open, setOpen] = useState(false);
  const [resolve, setResolve] = useState<
    ((value: Result | PromiseLike<Result>) => void) | null
  >(null);

  const title = useRef("");
  const message = useRef("");
  const itemTypeRef = useRef<RestoreItemType>("project");
  const textFieldRef = useRef<HTMLInputElement>(null);

  const openRestoreItemDialog = (itemType: RestoreItemType) => {
    switch (itemType) {
      case "project":
        title.current = "プロジェクトの復元";
        message.current = "プロジェクトを復元します。";
        itemTypeRef.current = "project";
        break;
      case "pileSet":
        title.current = "杭リストの復元";
        message.current = "杭リストを復元します。";
        itemTypeRef.current = "pileSet";
        break;
    }
    setOpen(true);
    return new Promise<Result>((res) => {
      setResolve(() => res);
    });
  };

  const RestoreItemDialog = () => {
    // Context
    const { projectProvided, setProjectProvided } = useContext(ProjectContext);
    const { objectProvided, setObjectProvided } = useContext(ObjectContext);
    const { setErrorStatusProvided } = useContext(ErrorStatusContext);
    const { setProgressProvided } = useContext(ProgressContext);

    const onClose = async (result: Result) => {
      switch (result) {
        case "confirm":
          switch (itemTypeRef.current) {
            case "project":
              break;
            case "pileSet":
              setProgressProvided(true);

              setProgressProvided(false);
              break;
          }
          break;
        case "cancel":
          break;
      }
      setOpen(false);
      if (resolve) {
        resolve(result);
      }
    };
    return (
      <ThemeProvider theme={darkTheme}>
        <RESTORE_ITEM_DIALOG
          open={open}
          onClose={onClose}
          itemType={itemTypeRef.current}
          title={title.current}
          message={message.current}
          textFieldRef={textFieldRef}
        />
      </ThemeProvider>
    );
  };

  return {
    RestoreItemDialog,
    openRestoreItemDialog,
  };
};

export default useRestoreItemDialog;
