export const fileUploadErrorAlert = () => {
  alert(
    "ファイルのアップロードに失敗しました。\n通信状況を確認してもう一度お試しください。"
  );
};

export const fileListErrorAlert = () => {
  alert("通信状況を確認してもう一度お試しください。");
};

export const ErrorAlert = (message: string) => {
  alert(message);
}

type FileUploadErrorType = "mesh" | "pile";

export const fileValidationErrorAlert = (fileType: FileUploadErrorType) => {
  let fileName;
  switch (fileType) {
    case "mesh":
      fileName = "地盤調査結果";
      break;
    case "pile":
      fileName = "杭リスト";
      break;
    default:
      return;
  }
  alert(
    `${fileName}のファイル形式が正しくありません。\nCSVファイルをご確認ください。`
  );
}