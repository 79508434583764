import React, { useState } from "react";
// MUI
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const loadUsers = async () => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
};

export default function RestoreSelect({ title }: { title: string }) {
  const [loading, setLoading] = useState<boolean>(false);


  return (
    <Autocomplete
      multiple
      fullWidth
      size="small"
      limitTags={4}
      id="user-select"
      options={restoreList}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      onOpen={async () => {
        setLoading(true);
        await loadUsers();
        setLoading(false);
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        );
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          placeholder="ユーザーを選択してください"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

const restoreList = [
{ id: 1, name: "Project A" },
{ id: 2, name: "Project B" },
{ id: 3, name: "Project C" },
{ id: 4, name: "Project D" },
{ id: 5, name: "Project E" },
{ id: 6, name: "Project F" },
{ id: 7, name: "Project G" },
{ id: 8, name: "Project H" },
{ id: 9, name: "Project I" },
{ id: 10, name: "Project J" },
];
